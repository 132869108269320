import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Mudra_ABI from "../../ABI/Mudra_ABI.json";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { connection } from '../../actions/connection';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ABI from "../../ABI/ABI.json";
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';
import { getuser, updateuser, getwithdraw, updateWithdraw, Reject, getEthtransaction, get_UpdateApproved } from '../../actions/users';
import config from '../../lib/config';
import axios from "axios";
import loadinggif from "../../assets/images/preloader1.gif";
import Web3 from 'web3';
import '@metamask/legacy-web3';


const contractAddr = config.contractAddr;
const smartcontract = config.smartcontract;
const OwnerAddr = config.owneraddr;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'name': "",
  'email': "",
  'phonenumber': "",
  'address1': "",
  'address2': "",
  'pincode': "",
  'city': "",
  'country': "",
  'Photofile': "",
  "status": ""

}

const useStyles = makeStyles(styles);

export default function WithDrawView(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [withdrawdet, setwithdrawdet] = useState();
  const [amount, setAmount] = useState();
  const [Withdrawstatus, setWithdrawstatus] = useState();
  const [useraddress, setuseraddress] = useState("");
  const [curruserid, setUserId] = useState();
  const [tableid, settTableId] = useState();
  const [waleamil, setemail] = useState('');
  const [windowTron, setwindowTron] = React.useState('');
  const [wallet_address, setWallet_address] = React.useState('---');
  const [upgradebtn, setupgradebtn] = React.useState('');
  const [trxtransferid, setTrxtxid] = useState('');
  const [transationID, setTransfer] = useState('');
  const [trxuserid, setcurruserid] = useState('');
  const [lasttransfer, setTransID] = useState('');
  const [AdminAdress, setAdminaddress] = useState("");
  const [curruserAddr, setuserAddr] = useState('');
  const [Transactionid, setTranId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [balance, setBln] = useState([]);




  const { userId } = useParams();
  console.log(userId, "asdfdsfdsfdsf");


  window.addEventListener('message', async function (e) {

    try {
      if (e.data.message && e.data.message.action == "tabReply") {
        console.log("tabReply event : ", e.data.message)
        if (typeof e.data.message.data != 'undefined') {
          if (typeof e.data.message.data.data != 'undefined') {
            if (typeof e.data.message.data.data.node != 'undefined') {
              if (typeof e.data.message.data.data.node.chain != 'undefined') {
                console.log("tronLink currently selects the main chain")
              } else {
                console.log("tronLink currently selects the side chain")
              }
            }
          }
        }
      }

      //console.log('------ e : ',e);

      if (e.data.message && e.data.message.action == "setAccount") {
        console.log('setAccount e.data : ', e.data)
        var wallet_address = e.data.message.data.address;
        var loginName = e.data.message.data.name;
        var getName = localStorage.getItem('qas4567d');
        var encodedString = window.btoa(loginName);
        var decodedString = window.atob(getName);

        setWallet_address(wallet_address);

        var currAddr = wallet_address;
        // currAddr = 'TTxzx4KYE6uyUGgJmpeDv3mkRMRLTwaaVz';
        var total_deposits = 0;
        if (currAddr) {
          var tronweb = tronWebconnect();


        } else {
          console.log('joinNowText : Join Now');

        }

        if (getName && getName != null) {
          console.log('getName : ', getName);
          if (decodedString != loginName) {
            console.log('decodedString : ', decodedString, ' - loginName : ', loginName);
            console.log('wallet connection : ', loginName);
            console.log('window.tronWeb : ', window.tronWeb);


            localStorage.removeItem("qas4567d");
            localStorage.setItem('qas4567d', encodedString);
            setTimeout(function () {
              setwindowTron(window.tronWeb);


            }, 2000);

          }
        } else {
          localStorage.setItem('qas4567d', encodedString);
        }
      }
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message)
        if (e.data.message.data.node.chain == '_') {
          console.log("tronLink currently selects the main chain")
        } else {
          console.log("tronLink currently selects the side chain")
        }
      }
    } catch (e) {
      console.log('wallet error', e)
    }
  });
  const getconnect = async () => {



    console.log("welcome");
    // var WEB3 = await connection();
    // console.log("WEB3333333333333",WEB3);
    // var currLoginAddr = WEB3.address;

    // if (WEB3.isMeta) {

    //   console.log("welcome Meta Mask"+currLoginAddr);
    //   setAdminaddress(currLoginAddr);
    // }
    // else{
    //   toast.error("kindly login metamask")

    // }
    if (window.ethereum) {

      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      try {
        if (typeof web3 !== 'undefined') {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider)
            if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function (error, result) {
                setAccounts(result[0]);
                var currAddr = window.web3.eth.defaultAccount;
                setAdminaddress(currAddr);
                window.web3.eth.getBalance(result[0], async (error, balance) => {

                  var currbal = balance / 1000000000000000000;
                  console.log("setBlnsetBlnyyyyyyyyyyy", currAddr);
                  setBln(currbal.toFixed(5))
                  console.log("setBlnsetBlnyyyyyyyyyyy", setBln);
                })
                if (window.web3.currentProvider.networkVersion == 3) {

                  //  setIsLoading(true);
                  //setShow(false)
                } else {
                  //  setIsLoading(false)
                  //  setShow(true)
                  // document.getElementById("loader_modal").style.display = "block";
                }
              })
            }
          })
        }
      } catch (err) { }
    }
  }


  function tronWebconnect() {
    if (windowTron && windowTron.defaultAddress && windowTron.defaultAddress.base58) {
      return windowTron;
    } else if (window.tronWeb && window.tronWeb.defaultAddress && window.tronWeb.defaultAddress.base58) {
      return window.tronWeb;
    } else {
      return false;
    }
  }








  const handleFormSubmit = async (e) => {
    console.log("muthu");
    e.preventDefault();

  }



  const getwithdrawList = async () => {

    const { result, userValue, data } = await getwithdraw(userId);
    console.log("test..........", userValue);
    if (userValue != undefined) {
      setAmount(userValue.amount)
      setUserId(userValue.userid)
      settTableId(userValue.id)
      setemail(userValue.email)
      setuseraddress(userValue.receiveaddr)

      if (userValue.status != undefined) {

        if (userValue.status == 1) {
          setWithdrawstatus("pending")

        } else if (userValue.status == 0) {
          setWithdrawstatus("Completed")

        } else if (userValue.status == 2) {
          setWithdrawstatus("Rejected")

        }

      }

    }
    setwithdrawdet(result);
  }


  const getUserData = async () => {
    console.log("userIduserIduserIduserIduserId".userId);
    var test = await getuser(userId);
    console.log("userIduserIduserId", test.userValue.receiveaddr)
    if (test.userValue != undefined) {

      setuserAddr(test.userValue.receiveaddr)

    }

  }

  useEffect(() => {
    //logout(history)
    getwithdrawList();
    getUserData();
    getconnect();

    getTransactionData();
  }, [])

  const getTransactionData = async () => {
    var test = await getEthtransaction();
    console.log('getTransactionData', test.userValue)
    let formdata = {};
    if (test && test.userValue != undefined) {
      if (test.userValue.length != 0) {


        var count = test.userValue.length - 1;
        console.log('getTransactionData', test.userValue[count]);

        setUser(test.userValue);

        formdata['id'] = test.userValue[count]._id;
        setcurruserid(test.userValue[count]._id);
        setTransID(test.userValue[count].useraddress)
      }
    }
  }


  const Approve = async () => {

    if (Withdrawstatus == 0) {
      toast.error("already completed withdraw process");
    }


    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async function () {
          const web3 = new Web3(window.web3.currentProvider)
          console.log(web3, "Web3")
          if (window.web3.currentProvider.isMetaMask === true) {
            web3.eth.getAccounts(function (error, accounts) {
              if (window.ethereum.networkVersion == 1) {
                toast.error("Please Choose wallet to Mainnet", toasterOption);
                return false;
              } else {
                //local test 
                //0x28e4972c2f2ab76af9303586dc5dfcbca06948fd
                //live test
                //0x28e4972c2f2ab76af9303586dc5dfcbca06948fd
                //console.log(tokenabi,tokencontractAddr,"===========================================accounts")
                var currAddr = accounts[0];
                var etherContract = window.web3.eth.contract(Mudra_ABI).at(config.MUDRA_CONTRACT_ADDRESS)
                var a = window.web3.toWei(amount, "ether")
                web3.eth.getBalance(currAddr, async (error, balance) => {
                  var currbal = balance / 1000000000000000000;
                  if (currbal == 0) {
                    toast.error("Insufficient balance for Gas Fee", toasterOption);
                    return false;
                  }
                  console.log(etherContract, "contrccontrccontrc", useraddress)
                  window.web3.eth.getGasPrice(async (error, getGasPrice) => {
                    var gaslimit = window.web3.toHex(30000000);
                    var getgasprice = window.web3.toHex(getGasPrice);
                    var fee = window.web3.toHex(getGasPrice) * gaslimit;
                    etherContract.transfer.sendTransaction(useraddress, a,
                      { from: currAddr, to: useraddress, gas: window.web3.toHex(100000), gasPrice: getGasPrice }, async function (err, transactionHash) {
                        if (transactionHash && transactionHash != '') {
                          console.log(transactionHash, "====", err, "=======================================hash")
                          var conEthamount = amount
                          console.log("current convert eth amount", conEthamount);
                          let approvInfo = {
                            "_id": tableid,
                            "userid": curruserid,
                            "userAddress": curruserAddr,
                            "userETHAmount": conEthamount,
                            "WithdrawStatus": Withdrawstatus
                          }
                          console.log("approved Info", approvInfo)

                          const { status, message } = await get_UpdateApproved(approvInfo);
                          toast.success(message, toasterOption);
                          history.push("/Mudrattrqqqq/withdrawlist")
                        } else {
                          toast.error('Transaction Failed', toasterOption);
                        }
                      })


                  })

                })
              }
            });

          } else {
            toast.error("Please connect your MetaMask", toasterOption);
          }
        });
      } catch (err) {
        var errmsg = (err.message && err.message != "") ? err.message : err;
        toast.error(errmsg, toasterOption);

      }
    } else {

      toast.error("Please connect your metamask", toasterOption);

    }



  }


  var failedCount = 1;


  const Rejectstatus = async () => {

    var Withdrawstatus = "Rejected";
    let reqData = { amount, Withdrawstatus, curruserid, tableid, waleamil };
    console.log("reqData-------------------->", reqData)



    let { error, result, userValue } = await Reject(reqData);
    console.log(result, 'jjjereeree')
    if (result) {

      toast.success("Withdraw status is Rejected", toasterOption);
      setTimeout(
        () => window.location.href = '/Mudrattrqqqq/withdrawlist',
        1000)

    } else {
      toast.error("Withdraw status is failed", toasterOption);

    }

  }
  console.log("user address----------------->", curruserAddr);
  return (

    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Withdraw Details</h4>
                <p className={classes.cardCategoryWhite}></p>
              </CardHeader>
              <CardBody>
                <GridContainer>


                  <GridItem xs={12} sm={12} md={3}>
                    <br /><br /><br />

                    <div className="djack">
                      <span><b>Mudra Amount:&nbsp;{amount} &nbsp;Mudra</b></span>
                    </div><br /><br />

                    <div className="withdraw" >
                      <span><b>Withdraw status:{Withdrawstatus}</b></span>
                    </div>



                  </GridItem>

                </GridContainer>
              </CardBody>

            </form>

            <div className="text-center mt-3">
              {((trxtransferid != '') || (trxtransferid != '' && transationID != 'SUCCESS')) &&

                <div className="gif_loader">

                  <img src={loadinggif} className="imgsrc" />

                </div>
              }


              {Withdrawstatus == "pending" &&
                <button className="btn btn-success mr-3" onClick={() => Approve()}>Confirm</button>}
              {Withdrawstatus == "pending" &&
                <button className="btn btn-danger" onClick={() => Rejectstatus()}>Reject</button>
              }
            </div>

          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
