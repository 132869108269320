import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import {toast} from 'react-toastify'
import { getdepositlist } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getkyc,gettransation,getFiatHistory } from '../../actions/users';
import { createFalse } from "typescript";
import SearchIcon from '@material-ui/icons/Search';
var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setUser] = useState();
    const [searchvalue,setsearchvalus]=useState("");

    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // const getKyc = async () => {
    //     var test = await getkyc();
    //     setUser(test.userValue);
        
    // }

   
    
   
    useEffect(() => {
    // getKyc();
    getfiathistory();

    }, [])

    const getfiathistory=async ()=>{

        const {fiatData}=await getFiatHistory();
        setUser(fiatData);

        console.log("fiatData",fiatData);
        

    }

   

         const handleChange=(e)=>{

            const value=e.target.value;
            setsearchvalus(value)  
            getSearchvalue(value);
    
    
        }
        const getSearchvalue= (searchvalue)=>{
        
            console.log("contact details",searchvalue)
    
            let fillerData=userdet.filter(value=>{
                console.log("values",value);
                  return (
                        value.Djackvalue && value.Djackvalue.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.amount && value.amount.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.paymentId && value.paymentId.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.status && value.status.toLowerCase().includes(searchvalue.toLowerCase()) ||
                       value.date && dateFormat(value.date,"dd-mm-yyyy") && dateFormat(value.date,"dd-mm-yyyy").toString()
                        .includes(searchvalue.toLowerCase())||
                        value.userid && value.userid.email && value.userid.email.toLowerCase().includes(searchvalue.toLowerCase())


    
    
              );
    
            })
            if(searchvalue != ""){
                setUser(fillerData);
            }else{
                getfiathistory();
            }
    
           
        }

    return (
        <div>
        
        <div className="page_header">
                <h2>Fiat History List</h2>
                <div className="page_header">
                <div class="input-group mr-3">
                <input type="text" class="form-control" value={searchvalue} onChange={handleChange} id="search" placeholder="search" />
                <div class="input-group-append">
                    <span class="input-group-text"><SearchIcon /></span>
                </div>
                </div>
                </div>
            </div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>User's Email</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Djackvalue</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Amount</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Payment ID</StyledTableCell>

                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Status</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Date</StyledTableCell>

                               {/*  <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Action</StyledTableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userdet && userdet.map((row) => {
                                return (
                                    <TableRow>
                                        <TableCell align={"left"}>{row.userid && row.userid.email} </TableCell >
                                        <TableCell align={"left"}>{row.Djackvalue} </TableCell >
                                        <TableCell align={"left"}>{row.amount} </TableCell >
                                        <TableCell align={"left"}>{row.paymentId} </TableCell >
                                        <TableCell align={"left"}>{row.status} </TableCell >
                                      <TableCell align={"left"}>{dateFormat(row.date,"dd-mm-yyyy")} </TableCell >
                            
                                                                  
                                                                       
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={userdet && userdet.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            </div>
    );
}
