// import package
import axios from "axios";

// import lib
import config from "../lib/config";

// import constant
import { SET_CURRENT_USER } from "../constant";
import { idText } from "typescript";

export const updateProfile = async (data) => {
  // console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("mobilenumber", data.mobilenumber);
    bodyFormData.append("photo", data.photo);
    bodyFormData.append("company", data.company);
    bodyFormData.append("designation", data.designation);
    bodyFormData.append("detail", data.detail);
    //console.log(bodyFormData,"fdsfdsfsdfsdfsdfsdfsdfdsfsdfdsfdsfsdfsdfsdfsdfsdfsdfsdfsdf8888888888888888888888");
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateProfile`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateSettings = async (data) => {
  // console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateSettings`,
      data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const update2FAUsers = async (data) => {
  console.log(data, "varidddddddddddddd");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/update2FAUsers`,
      data,
    });
    console.log("resDataiaiaiaiaiia", respData);
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updateManualAmount = async (data) => {
  // console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateManualAmount`,
      data,
    });
    console.log("resDataiaiaiaiaiia", respData);
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updatecontact = async (data) => {
  console.log(data, "data");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatecontact`,

      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const get_UpdateApproved = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/approvedContact`,

      data,
    });
    // console.log("res.data.information",respData)     ;
    if (respData.data.status) {
      return {
        status: respData.data.status,
        message: respData.data.message,
      };
    } else {
      return {
        status: respData.data.status,
        message: respData.data.message,
      };
    }

    return {
      loading: false,
    };
  } catch (err) {
    console.log("err===============", err.response);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateuser = async (data) => {
  //console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phonenumber", data.phonenumber);
    bodyFormData.append("address1", data.address1);
    bodyFormData.append("address2", data.address2);
    bodyFormData.append("pincode", data.pincode);
    bodyFormData.append("city", data.city);
    bodyFormData.append("country", data.country);
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("userId", data.userId);
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateuser`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const adduser = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("password", data.password);
    bodyFormData.append("phonenumber", data.phonenumber);
    bodyFormData.append("address1", data.address1);
    bodyFormData.append("address2", data.address2);
    bodyFormData.append("pincode", data.pincode);
    bodyFormData.append("city", data.city);
    bodyFormData.append("country", data.country);
    bodyFormData.append("Photofile", data.Photofile);
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/adduser`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addAdmin = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addAdmin`,
      headers: {
        Authorization: localStorage.admin_token,
      },

      data: data,
    });
    console.log("respdataieiieieieie", respData);
    return {
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getToken = async () => {
  console.log("dataaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getToken`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log("respData", respData);
    return {
      loading: false,
      DJvalue: respData.data.test,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const gettransation = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/gettransation/`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log(respData, "respData!!!!!!!!!!");

    return {
      loading: false,
      userValue: respData.data.userValue,
      transactionCount: respData.data.transactionCount,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getFiatHistory = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getFiatHistory/`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log(respData, "respData");

    return {
      loading: false,
      fiatData: respData.data.fiatData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addTimer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addTimer`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("RespData---", respData);
    return {
      status: respData.status,
      loading: false,
      result: respData.data.messages,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addToken = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addToken`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addfaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addfaq`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatefaq = async (data) => {
  console.log(data, "data");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatefaq`,

      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/login`,

      data,
    });

    localStorage.setItem("admin_token", respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuser = async (userId, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuser/` + userId,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("jjjjjjjj",respData.data);

    // console.log("jjjjjjjj",respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserDetails = async (userId, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserDetails/` + userId,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("jjjjjjjj",respData.data);

    // console.log("jjjjjjjj",respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getupiuserDetails = async (userId, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getupiuserDetails/` + userId,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("jjjjjjjj",respData.data);

    // console.log("jjjjjjjj",respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getwithdraw = async (id, dispatch) => {
  console.log("1y11es");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getwithdraw/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("jjjjjjjj",respData.data);

    // console.log("jjjjjjjj",respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaq = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getfaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteuser = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deleteuser/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deleteAdmin = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/deleteAdmin/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log("respdataaaaaaaaaaa", respData);
    return {
      loading: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deletecontact = async (id, dispatch) => {
  console.log("deletecontact", id);
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletecontact/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletesupport = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletesupport/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletesupportchat = async (id, data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletesupportchat/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deletfaq = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletefaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsettdata = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getsettdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserdata = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserlist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserlist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getadminlist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getadminlist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatestatus = async (status, id) => {
  try {
    var data = {
      status: status,
      id: id,
    };
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatestatus`,
      data,
    });
    return {
      loading: false,
      data: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdepositlist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getdepositlist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getkyc = async (token, dispatch) => {
  console.log("yesdadsad");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getkyc`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getupii = async (token, dispatch) => {
  console.log("yesdadsad");
  //alert("welcome")
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getupi`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatekycStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatekycStatus`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatefiatStatus = async (data) => {
  console.log("dataaaaaaa", data);

  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatefiatStatus`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatefiatStatusreject = async (data) => {
  console.log("dataaaaaaa", data);

  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatefiatStatusreject`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getKycHisotory = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getKycHisotory/` + id,
    });
    console.log("kkkkkkkkkkkkkk", respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    console.log("errr", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getusercount = async () => {
  console.log("uuuuu");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getusercount`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getsupportcount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getsupportcount`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaqlist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getfaqlist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCurrentUser = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API.userService}/api/currentUser`,
      headers: {
        Authorization: token,
      },
    });
    dispatch(setCurrentUser(respData.data.result));
    return true;
  } catch (err) {
    return false;
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const refreshData = (data, dispatch) => {
  console.log("objectssssssssssssssss", data);
  dispatch(setCurrentUser(data));
};

export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
};

export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/resetPassword`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSupportDetails = async (token, dispatch) => {
  // console.log('yesdadsad')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getSupportDetails`,
    });
    // console.log("dddddddddddddd",respData.data)
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSupportList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getSupportList`,
    });
    console.log("supportData", respData);
    return {
      loading: false,
      supportDetails: respData.data.supportData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getChats = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getChats/` + id,
    });
    return {
      getChats: respData.data.ticketDatas[0].reply,
      ticket_id: respData.data.ticketDatas[0]._id,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const adminReplay = async (data, id) => {
  console.log("yes", data);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/adminReplay/` + id,
      data,
    });
    return {
      data: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getemailTemplateList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getemailTemplateList`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getemailTemplate = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getemailTemplate/` + id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    console.log(respData.data);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSubscripeUsers = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getSubscripeUsers/`,
    });

    console.log("reeererererererre", respData);
    return {
      loading: false,
      result: respData.data.subAllUsers,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getEmailUsers = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getEmailUsers/`,
    });

    console.log(respData, "getEmailUsersgetEmailUsersgetEmailUsers");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updateEmailTemplate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateEmailTemplate`,
      data,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sendSupscripeEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/sendSupscripeEmail`,
      data,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getContactUs = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getContactUs`,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getContactDetails = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getContactDetails/` + id,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getWithdrawList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getWithdrawList`,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getWithdrawDetails = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getWithdrawDetails/` + id,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateWithdraw = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateWithdraw`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const Reject = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/Reject`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getEthtransaction = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getEthtransaction/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCmsList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsList`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.cmsData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCmsData = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsData/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.cmsData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateCmsData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateCmsData`,
      data,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getgraphicalcount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getgraphicalcount/`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      results: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getGraphicalDateData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getGraphicalDateData/`,
      data,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      results: respData.data.result,
      csvResult: respData.data.csvResult,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addCmsImage = async (data) => {
  console.log(data.Photofile, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("Photofile", data.Photofile);

    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addCmsImage`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCmsImage = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsImage/`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsImagedata: respData.data.cmsImagedata,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCmsImageEdit = async (id) => {
  console.log("id", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsImageEdit/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsImagedata: respData.data.cmsImagedata,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateCmsImage = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("Id", data.Id);

    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateCmsImage`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteCmsImage = async (id) => {
  console.log("id", id);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/deleteCmsImage/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsImagedata: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getReferalHistory = async (token, dispatch) => {
  // console.log('yesdadsad')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/referal_history`,
    });
    console.log("dddddddddddddd", respData.data);
    return {
      loading: false,
      result: respData.data.referalData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const AddBankData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/bankdetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("RespData---", respData);
    return {
      loading: false,
      result: respData.data.data,
      status: respData.data.success,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getBankData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/bankdetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("RespData---", respData);
    return {
      status: respData.data.success,
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
