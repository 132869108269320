import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { getfaqlist,deletfaq } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setUser] = useState();
    const history=useHistory();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

   function editR(id) 
    {
        if(id!='') {

            window.location.href="/Mudrattrqqqq/faqedit/"+id;

        }
        
    }
   
    function deleteR (id) {
        if(id!='') {
            deletfaq(id);
            toast.success("Deleted Success",toasterOption);
           window.location.href="/Mudrattrqqqq/faqList/"
        }
    }


    const getFaqList = async () => {
        var test = await getfaqlist();
        console.log(test.userValue)
        setUser(test.userValue);
    }
    const add =async () => {
        window.location.href="/Mudrattrqqqq/faqadd";
    }
    
    useEffect(() => {
    //logout(history)
     getFaqList();
    }, [])

    return (
        <div>
            <div className="page_header">
                <h2>Faq List</h2>
                <Button variant="contained" color="primary" onClick={add}>Add</Button>
            </div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Question</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Answer</StyledTableCell>
                                <StyledTableCell align={"left"} style={{ minWidth: "170" }}>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userdet && userdet.map((row) => {
                                return (
                                    <TableRow>
                                        <TableCell  align={"left"} > {row.question} </TableCell>
                                        <TableCell align={"left"}> {row.answer} </TableCell >
                                        <TableCell align={"left"}>
                                            {/* <div onClick={viewuser} id={row._id}><VisibilityIcon  style={{ color: "#318c5d" }} /></div> */}
                                            <Link onClick={() => editR(row._id)}><EditIcon  style={{ color: "#109ebf" }} /></Link>
                                            <Link onClick={() => deleteR(row._id)} ><DeleteIcon   style={{ color: "#b8256e" }} /></Link>
                                        </TableCell >
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={userdet && userdet.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            </div>
    );
}
