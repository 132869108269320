/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import React, { useState, useEffect,Fragment } from "react";
import Person from "@material-ui/icons/Person";
import List from '@material-ui/icons/List';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Userlist from "views/User/UserList.js";
import Useradd from "views/User/Useradd.js";
import Adminadd from'views/subadmin/adminAdd.js'
import SubAdmin from 'views/subadmin/subadmin.js'
import Useredit from "views/User/Useredit.js";
import Faqlist from "views/Faq/FaqList.js";
import Faqadd from "views/Faq/Faqadd.js";
import Faqedit from "views/Faq/Faqedit.js";
import UserSettings from "views/UserProfile/UserSettings.js";
import Deposit from "views/Deposit/Deposit.js";
import Kyc from "views/Kyc/kyc.js";
import kycView from "views/Kyc/kycView.js";
import Transaction from 'views/Transaction/transaction.js';
import SupportDetailsList from 'views/support/supportList';
import ViewTicketList from 'views/support/viewTicketList';
import kycHistoryList from 'views/Kyc/kycHistoryList';
import EmailTemplateList from 'views/EmailTemplate/EmailTemplateList';
import EmailTemplateEdit from 'views/EmailTemplate/emailTemplateEdit';
import Djackmangement from 'views/DJackManagement/djmanagement';
import Timermangement from 'views/TimerManagement/timermanagement';

//Add Bank - Admin
import AdminBankManage from './views/AdminBankDetail/addBank';

import ContactUs from 'views/Contact Us/contact_us';
import ReferalHistory from './views/referalManagement/referalHistory'
import FiatUsers from './views/fiat-menu/fiat'
import ViewContactDetailes from 'views/Contact Us/viewContactDetails';
import WithDrawList from 'views/Withdraw/withDrawList';
import WithDrawViewViewDetails from 'views/Withdraw/withDrawView';
import withDrawDetails from 'views/Withdraw/withDrawDetails';
import CmsList from 'views/CmsList/cmsList';
import CmsEdit from 'views/CmsList/cmsEdit';
import CmsImageList from 'views/cmsImageupdate/imageList'
import CmsImageAdd from 'views/cmsImageupdate/cmsImageadd';
import subscribeImage from 'views/EmailTemplate/SendEmailTemplate'
import CmsImageEdit from 'views/cmsImageupdate/cmsImageEdit';
import FiatList from 'views/Transaction/fiatList';
import { shallowEqual, useSelector } from 'react-redux'
import NewsLetter from 'views/NewsLetter/NewsLetter'
import jwt_decode from "jwt-decode";
import { setCurrentUser } from './actions/users';
//import { dispatchFun } from './actions/useDispatch';
import { useDispatch } from 'react-redux';
import store from "store";



const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
    menu : "/sidemenu"
  },
  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    menu : ""
  },
 
   {
    path: "/userlist",
    name: "User Management",
    icon: List,
    component: Userlist,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/useradd",
    name: "User add",
    icon: List,
    component: Useradd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/adminadd",
    name: "User add",
    icon: List,
    component: Adminadd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/subadmin",
    name: "subAdmin add",
    icon: List,
    component: SubAdmin,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/adminAddbank",
    name: "Admin Bank Manage",
    icon: List,
    component: AdminBankManage,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/fiatInfo",
    name: "fiat Users",
    icon: List,
    component: FiatUsers,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/useredit/:userId",
    name: "User edit",
    icon: List,
    component: Useredit,
    layout: "/admin",
    menu : ""
  },
 
  {
    path: "/cmsList",
    name: "Cms List",
    icon: List,
    component: CmsList,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/cmsEdit/:Id",
    name: "Cms Edit",
    icon: List,
    component: CmsEdit,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/faqadd",
    name: "Faq add",
    icon: List,
    component: Faqadd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/faqList",
    name: "Faq List",
    icon: List,
    component: Faqlist,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/faqedit/:faqId",
    name: "Faq edit",
    icon: List,
    component: Faqedit,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/kyclist",
    name: "Kyc Management",
    icon: List,
    component: Kyc,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/newsletter",
    name: "News Letter",
    icon: List,
    component: NewsLetter,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/kycview/:id",
    name: "Kyc",
    icon: List,
    component:kycView,
    layout: "/admin",
    menu : ""
  },
  //  
  {
    path: "/kycHistoryList/:id",
    name: " kycHistoryList",
    icon: List,
    component: kycHistoryList,
    layout: "/admin",
    menu : ""
  },
 
  {
    path: "/supportDetailslist",
    name: "supportDetails List",
    icon: List,
    component: SupportDetailsList,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/transaction",
    name: "Transaction Management",
    icon: List,
    component: Transaction,
    layout: "/admin",
    menu : "/sidemenu"
  },

  {
    path: "/ViewTicketList/:id",
    name: "ViewTicketList",
    icon: List,
    component:ViewTicketList,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/withdrawlist",
    name: "WithDraw Management",
    icon: List,
    component: WithDrawList,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/withDrawView/:userId",
    name: "View WithDraw Details",
    icon: List,
    component:WithDrawViewViewDetails,
    layout: "/admin",
    menu : ""
  },
    {
    path: "/withDrawDetails/:userId",
    name: "View WithDraw Details",
    icon: List,
    component:withDrawDetails,
    layout: "/admin",
    menu : ""
  },
   {
    path: "/WithEditList/:id",
    name: "Edit WithDraw Details",
    icon: List,
    component:WithDrawViewViewDetails,
    layout: "/admin",
    menu : ""
  },

  {
    path: "/emailTemplateList",
    name: "EmailTemplate List",
    icon: List,
    component: EmailTemplateList,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/referal_history",
    name: "Referal History",
    icon: List,
    component: ReferalHistory,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/EmailTemplateEdit/:Id",
    name: "EmailTemplate Edit",
    icon: List,
    component: EmailTemplateEdit,
    layout: "/admin",
    menu : ""
  },
 
  
  {
    path: "/imageadd",
    name: "Cms Image Add",
    icon: List,
    component: CmsImageAdd,
    layout: "/admin",
    menu : ""
  },
 
  {
    path: "/imageEdit/:Id",
    name: "Cms Image Edit",
    icon: List,
    component: CmsImageEdit,
    layout: "/admin",
    menu : ""
  },
  // {
  //   path: "/fiatList",
  //   name: "Fiat History",
  //   icon: List,
  //   component: FiatList,
  //   layout: "/admin",
  //   menu : "/sidemenu"
  // },
  // {
  //   path: "/imageList",
  //   name: "Cms Image Mangement",
  //   icon: List,
  //   component: CmsImageList,
  //   layout: "/admin",
  //   menu : "/sidemenu"
  // },
   {
    path: "/MudraMangement",
    name: "Token Mangement",
    icon: List,
    component: Djackmangement,
    layout: "/admin",
    menu : "/sidemenu"
  },
 
  {
    path: "/TimerMangement",
    name: "Timer Mangement",
    icon: List,
    component: Timermangement,
    layout: "/admin",
    menu : "/sidemenu"
  },
 

  // {
  //   path: "/contactUs",
  //   name: "Contact Us",
  //   icon: List,
  //   component: ContactUs,
  //   layout: "/admin",
  //   menu : "/sidemenu"
  // }, 
  // {
  //   path: "/viewContactDetailes/:id",
  //   name: "Contact Details",
  //   icon: List,
  //   component:ViewContactDetailes,
  //   layout: "/admin",
  //   menu : ""
  // },
  
];


export default dashboardRoutes;
