import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import socketClient from "socket.io-client";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';
import config from '../../lib/config';
import { updatekycStatus, getuserDetails } from '../../actions/users';


// const SERVER = "http://localhost:3001/"
// var socket = socketClient(SERVER);
// const token = localStorage.admin_token;
// console.log("tt", token);
// const decoded = jwt_decode(token);
// console.log("decode", decoded._id)
// socket.emit('CREATEROOM', decoded._id)

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const initialFormValue = {
  'name': "",
  'email': "",
  'mobilenumber': "",
  'photo': "",
  'company': "",
  'designation': "",
  'detail': "",

}


const useStyles = makeStyles(styles);



export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [userdet, setUser] = useState();    
  const[reason,setreason]=useState('');
  const [validateError,setvalidateError]=useState('')
  const [noimage,setNoimg]=useState('')

  const[reject,setreject]=useState(false);
  const { id } = useParams();

  const getUserData = async () => {
    var test = await getuserDetails(id);
    console.log(test,"kkkkkkkkkkkkkkkkkk");
if (test.userValue!=undefined) {
    var kyc = test.userValue.kyc;
    console.log(kyc.length,"lllllllllllllllllllllllll");
    if(kyc.length==0)
    {
      // var newimage = {}; 
      // newimage ["addressproof"] = "noImages"
      // newimage ["idprooffrond"] = "noImages"
      // newimage ["idproofback"] =  "noImages"
      // newimage ["idwithselfie"] =  "noImages"

      var noimg=config.API+"/images/email_images/noimage.png";
      console.log(noimg,'2daarjeejr')

      setUser("noImages")
         setNoimg(noimg);
     

    }
    else{

   
     var lastimage = kyc[kyc.length -1];
     var newimage = {}; 
     newimage ["addressproof"] =  `${config.API}/images/kyc/` + lastimage.addressproof
     newimage ["idprooffrond"] =  `${config.API}/images/kyc/` + lastimage.idprooffrond
     newimage ["idproofback"] =  `${config.API}/images/kyc/` + lastimage.idproofback
     newimage ["idwithselfie"] =  `${config.API}/images/kyc/` + lastimage.idwithselfie
        setUser(newimage);
  }
}else{

}

}

  useEffect(() => {
    getUserData();
  }, [])

  const rejected=()=>{
      setreject(true);
  }
 const   reasonChange=(e)=>{
    e.preventDefault();
    const {  value } = e.target;
    setreason(value)
 }
 const kycstatus = async (status,statusMessage) => {
    var data = { status: status , id : id,rejectReason:reason,statusMessage:statusMessage}
   let { error } = await updatekycStatus(data);
        console.log(error);
  if (isEmpty(error)) {
      toast.success('Kyc status Updated', toasterOption);
       history.push('/kyclist')
  }
  else{
    setvalidateError(error);
  }
}
  const back = async () => {
  //history.push('/admin/transaction')
  window.location.href='/Mudrattrqqqq/kyclist';
  

}

  return (
    <div>
      <div className="page_header">
         <button className="btn btn-success mr-3" onClick={() => back()}>Back</button> 
      </div>
      <GridContainer>
        {console.log(userdet)}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>View Kyc</h4>
                <p className={classes.cardCategoryWhite}>User </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
              
                <GridItem xs={12} sm={12} md={12}> <h3 style={{ fontWeight: 'bold' }}>Address Proof</h3></GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                  {/* <h3 style={{ fontWeight: 'bold' }}>ID Proof</h3> */}

                    <h5 style={{ fontWeight: 'bold' }}>Front Side</h5>
                    {
                      userdet&& userdet=="noImages" ?
                      <img className="mt-3 w-100 noImage" style={{ height :"150px" ,width:"100px"} } src={noimage}/>
                      :
                      <img className="mt-3 w-100" src={userdet && userdet.idprooffrond}/>

                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                     <h5 style={{ fontWeight: 'bold' }}>Back Side</h5>      
                   {
                      userdet&& userdet=="noImages" ?
                      <img className="mt-3 w-100 noImage" style={{ height :"150px" ,width:"100px"} } src={noimage}/>
                      :
                      <img className="mt-3 w-100" src={userdet && userdet.idproofback}/>

                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}> <h3 style={{ fontWeight: 'bold' }}>ID Proof</h3></GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                  {/* <h3 style={{ fontWeight: 'bold' }}>Address Proof</h3> */}
                  

                  <h5 style={{ fontWeight: 'bold' }}> Front Side User ID Proof</h5>
                    {
                      userdet&& userdet=="noImages" ?
                      <img className="mt-3 w-100 noImage" style={{ height :"150px" ,width:"100px"} } src={noimage}/>
                      :
                      <img className="mt-3 w-100" src={userdet && userdet.addressproof}/>

                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <h3 style={{ fontWeight: 'bold' }}>Selfie with ID Proof</h3>
                    {
                      userdet&& userdet=="noImages" ?
                      <img className="mt-3 w-100 noImage" style={{ height :"150px" ,width:"100px"} } src={noimage}/>
                      :
                      <img className="mt-3 w-100" src={userdet && userdet.idwithselfie}/>

                    }
                  </GridItem> 
                </GridContainer>
                {
                reject!=true &&
                <div className="text-center mt-3">
                   {
                      userdet&& userdet!="noImages"  &&
                    <button className="btn btn-success mr-3" onClick={() => kycstatus(2,"approved")}>Approve</button>      
                  }
                   {
                      userdet&& userdet!="noImages"  &&
                      <button className="btn btn-danger" onClick={() => rejected()}>Reject</button>
                    }
                  
                </div>
                }
               

                {reject!=false &&
                       
                    <GridItem xs={12} sm={12} md={12} className="mt-10">      
            <label  className="mt-3"  style={{ fontWeight: 'bold' }}>Reason:</label>
            <textarea className="w-100" rows="3" onChange={reasonChange} value={reason || ''}></textarea>
            {
                validateError.rejected && <span className="text-danger">{validateError.rejected}</span>
              }
            <div className="text-center"><button className="btn btn-danger" onClick={() => kycstatus(3,"rejected")}>Submit</button></div>   
            </GridItem>
          

            }
              </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
