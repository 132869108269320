import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import {  addTimer ,getToken} from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
 
  'dateValue': "",
  'round':"",
  'total':"",
  'current':"",
  
}

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const[date,setDate]=("")
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  useEffect(()=>{
     // gettoken();
  },[]);
 

const gettoken= async ()=>{

    const data=await getToken();
    console.log("111",data.DJvalue);
    const formdata={}
  //  formdata["dateValue"]   =data.DJvalue.Djacktoken;
    formdata["referral"]   =data.DJvalue.referralAmount;
    formdata["Bonus"]      =data.DJvalue.Bonus;
    formdata["mindeposit"] =data.DJvalue.mindeposit;
    formdata["minwithdraw"]=data.DJvalue.minwithdraw;

    setFormValue(formdata);

}
  // function
  const handleDate = (e) => {
    e.preventDefault();
    var varDate = new Date(e.target.value);
   var date=new Date()
   if(varDate >= date) {
   
   const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }

   setFormValue(formData)
    }
    else{
      toast.error("Select Date Greater than Current Date");
   //   setDate("")
      
    }
    
  }
  const handleChange=(e)=>{
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }

   setFormValue(formData)

  }

    const {
    
        dateValue,round,total,
        current
       
    } = formValue

  const handleFormSubmit = async (e) => {
   
    e.preventDefault();
    console.log(formValue);
    let reqData = {
      
        dateValue,
        round,
        total,
        current
       
    }
   let { error,result,status,messages} = await addTimer(reqData);
    
      if(status==200){
        setFormValue("")
        toast.success("Details Updated Successfully");
      
      }
     else {
       setValidateError(error);
     }
    // if (isEmpty(error)) {
    //     setValidateError('');
    //     getToken();
    //   toast.success(result, toasterOption);
    // } else {
    //   setValidateError(error);
    // }
  }
  
  return (
    <div>
    
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Landing Page Controller</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                  {/* <GridItem xs={12} sm={12} md={3}> */}
                  <GridItem xs={12} sm={12} md={12}> <h5 style={{ fontWeight: 'bold' }}>Change End Date</h5></GridItem>
                  <GridItem xs={12} sm={12} md={3}>
              <label htmlFor="inputPassword4" className="auth_label">Select End Date</label>
             
              <input type="date" className="auth_inp" id="dateValue" value={dateValue} onChange={handleDate} />
              </GridItem>
              <br/> <br/> <br/>
              <GridItem xs={12} sm={12} md={3}>
              <label htmlFor="inputPassword4" className="auth_label">Enter Round</label>
             
              <input type="text" className="auth_inp" id="round" value={round} onChange={handleChange} />
              </GridItem>
              <br/> <br/> <br/>
              <GridItem xs={12} sm={12} md={3}>
              <label htmlFor="inputPassword4" className="auth_label">Enter Total USD</label>
             
              <input type="text" className="auth_inp" id="total" value={total} onChange={handleChange} />
              </GridItem>
              <br/> <br/> <br/>
              <GridItem xs={12} sm={12} md={3}>
              <label htmlFor="inputPassword4" className="auth_label">Enter Current USD</label>
             
              <input type="text" className="auth_inp" id="current" value={current} onChange={handleChange} />
              </GridItem>
             
                
                </GridContainer>
              
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>  

          
      </GridContainer>
    </div>
  );
}
