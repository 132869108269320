import React, { useState, useEffect,Fragment } from "react";
import { useDispatch,useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {toast} from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { getuserlist,updatestatus,deleteuser,update2FAUsers  } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";

import SearchIcon from '@material-ui/icons/Search';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import { updateManualAmount } from '../../actions/users';

import isEmpty from '../../lib/isEmpty';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


  toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setUser] = useState([]);
    const [searchvalue,setsearchvalus]=useState("");
    const [email,setemail]=useState("");
    const [useramountforupdatemanuvaltokenbut,setuseramountforupdatemanuvaltokenbut]=useState("");
    const [userId,setUserid]=useState("");
    const[responsive,setresponsive]            = useState(true);
    const [showModal, setShow] = useState(false);
    const[oneYearPlan,setoneYearPlan]               = useState(true);
    const[twoYearPlan,settwoYearPlan]               = useState(false);
    const [amount,setamount]=useState("");
    const [validateError, setValidateError] = useState({});
    const currentUser = useSelector(state =>state.auth )
    console.log("currentUsercurrentUsercurrentUser",currentUser)

const columns = [
    {
        

        key: "_id",
        text: "User Id",
        className: "_id",
        align: "left",
        sortable: true
        
      },
    {
        

      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true
      
    },
   {
      key: "email",
      text: "Email",
      className: "Email",
      align: "left",
      sortable: true,
    

    },
   
    {
      key: "userlist",
      text: "Token",
      className: "amount",
      align: "left",
      sortable: true,
      cell: record => {
          
        return (
            <Fragment>
              {record && record.userlist && record.userlist.length>0 && record.userlist[0].Mudravalue}

            </Fragment>
        )
      }

    },
    {
        key: "phonenumber",
        text: "Mobile",
        className: "Mobile",
        align: "left",
        sortable: true,
  
      },
    {
        key: "date",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell:record=>
            // console.log(record.date,"date")
           <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
      },
  
    {
        
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                     
                    const checkin = record.status;
                   
                    if(checkin=='0'){
                        var lockVal = 'fa fa-lock';
                    } else{
                        var lockVal = 'fa fa-unlock';
                    }
                   
                    return (
                        <Fragment>
                        {/* <button
                                data-toggle="modal"
                                data-target="#update-template-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => editR(record.id)}
                               
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button> */}
                            {/* <button
                                className="btn btn-danger btn-sm mr-1"  
                                onClick={() => deleteR(record._id)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                             <button 
                               className="btn btn-primary btn-sm mr-1"
                               onClick={()=>activeStatus(record.status,record._id)}>
                                <i className={lockVal}></i>
                            </button>
                           


                        </Fragment>
                    );
                }
            },

            {
                key: "action",
                text: "Disable 2FA",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record,'recordssssss');
                    const checkin = record.status;
                    console.log(checkin,'checkineeed');
                    if(checkin=='0'){
                        var lockVal = 'fa fa-lock';
                    } else{
                        var lockVal = 'fa fa-unlock';
                    }
                   
                    return (
                        <Fragment>
                        <button
                                data-toggle="modal"
                              
                                className="btn btn-primary btn-sm"

                                onClick={() => Update2FA(record._id)}
                               
                                style={{marginRight: '5px'}}> Disable 2 Fa
                               
                            </button>
                         
                           


                        </Fragment>
                    );
                }
            },

            {
                key: "action",
                text: "Manual ICO",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record,'recordssssss');
                    const checkin = record.status;
                    console.log(checkin,'checkineeed');
                    if(checkin=='0'){
                        var lockVal = 'fa fa-lock';
                    } else{
                        var lockVal = 'fa fa-unlock';
                    }
                    
                   
                    return (

                        <Fragment>
                            
                            {currentUser.user.Manualbuy==true&&
                            <button
                            data-toggle="modal"
                          
                            className="btn btn-primary btn-sm"
                            onClick={() => Update(record)}
                           
                            style={{marginRight: '5px'}}> Manual Buy Token
                           
                        </button>
                            }
                            
                        
                         
                           


                        </Fragment>
                    );
                }
            },
  ];
 const configdata = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                print: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };


   function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    async function Save()
    {
       
        console.log("amounttntnntnntnt",oneYearPlan,twoYearPlan);
        let reqData = {
            email,
            amount,
            oneYearPlan,
          twoYearPlan,
          }
      
          let {error ,result} = await updateManualAmount(reqData);
          if (isEmpty(error)) {
            toast.success(result, toasterOption);
            setValidateError("");
            window.location.href="/Mudrattrqqqq/userlist";
           
          } else {
            toast.success(error, toasterOption);
            setValidateError(error);
          }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

   function editR(id) 
    {
       // alert(e.target.id);
        if(id!='') {
            window.location.href="/Mudrattrqqqq/useredit/"+id;
        }
        
    }
    function Update(record)
    {
        console.log("***********************",record);
        setShow(true);
      var  Mudravalue=0;
        if(record){
            setemail(record.email);
            if(record && record.userlist && record.userlist[0] && record.userlist[0].Mudravalue !=''){
                setuseramountforupdatemanuvaltokenbut(record.userlist[0].Mudravalue)
            }
        }
       
        
        console.log("recidirieireireireireirere",record);
    }
    
   async function Update2FA(id)
    { console.log("recidirieireireireireirere",id);
      //  setShow(true);
        var data={'id':id};
      var {result,message}=await   update2FAUsers(data)
      toast.success(result)
        setUserid(id);
       
    }


    // const viewuser = (e) => {
    //     console.log(e.target.id);
    // }
    function deleteR (id) {
        if(id!='') {
            deleteuser(id);
            toast.success("deleted success",toasterOption);
           setTimeout(
        ()=> window.location.href="/Mudrattrqqqq/userlist",
        1000);

        }
    }


    const getUserList = async () => {
        var test = await getuserlist();
        console.log("tessssssss",test.userValue)
        setUser(test.userValue);
    }
    const add =async () => {
          setTimeout(
        ()=>window.location.href="/Mudrattrqqqq/useradd",
        1000);
    }
    
    useEffect(() => {
    //logout(history)
     getUserList();
    }, [])
    const handleClose = () => setShow(false);

    const activeStatus= async (statuss,id)=>{
      
        const status=await updatestatus(statuss,id)
        toast.success(status.data)

        setTimeout(()=>{
            window.location.reload();
        },1000)
      
        
    }
    const handleChange=(e)=>{

        const value=e.target.value;
        setsearchvalus(value)  
        getSearchvalue(value);


    }
    // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = {  ['amount']: value } 
    setamount(formData)
  }
  const radioOnChange =(e)=> {
    const  {id,checked} = e.target
     if(id=="oneYearPlan"){
        setoneYearPlan(checked)
        settwoYearPlan(!checked)
     }else{
      setoneYearPlan(!checked)
       settwoYearPlan(checked)
     }
  }

    const getSearchvalue= (searchvalue)=>{
    
        console.log("contact details",searchvalue)

        let fillerData=userdet.filter(value=>{
            console.log("values---------->",value);
              return (
                value._id && value._id.toLowerCase().includes(searchvalue.toLowerCase()) ||
                value.name && value.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.phonenumber && value.phonenumber.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.email && value.email.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.amount && value.userlist[0].amount||
                    value.country && value.country.toLowerCase().includes(searchvalue.toLowerCase()) 

          );

        })
        if(searchvalue != ""){
            setUser(fillerData);
        }else{
            getUserList();        
        }

       
    }
    return (
        <div>
            <div className="page_header">
                <h2>Users List</h2>
                <div className="page_header">
                <div class="input-group mr-3">
                </div>
                <Button variant="contained" color="primary" onClick={add}>Add</Button>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} >


<Modal.Header closeButton>
    <Modal.Title>Upate User Status</Modal.Title>
</Modal.Header>
<Modal.Body>
    <div class="form-group">
        <label for="grade">email</label>
        <input type="text" value={email} disabled ></input>
       
    </div>
    <div class="form-group">
        <label for="grade">Enter your amount</label>
        <input type="text" id="amount" onChange={onChange}  placeholder="enter your amount" ></input>
      
    </div>
    <div className="form-group col-md-12 col-lg-12">
                          <div className="radio_paran_container ">
                          <label class="radio_container">One Year plan<b>*</b>
                          <input type="radio" checked={oneYearPlan} onChange={radioOnChange} id="oneYearPlan" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="radio_container">Two Year plan<b>*</b>
                          <input type="radio" checked={twoYearPlan} onChange={radioOnChange} id="twoYearPlan" />
                          <span class="checkmark"></span>
                        </label>
                          </div>
                         

                          {/* <a href="/planDetails" className="a_hl_wet" target="_blank">View plan details</a> */}
                      </div>
</Modal.Body>

<Modal.Footer>
<div className="form-group col-md-12 col-lg-12">
<label lassName="a_hl_wet" for="grade">User Balance</label>
 <input type="number" id="userwalletamount"  value={useramountforupdatemanuvaltokenbut} disabled></input>
    </div>
    <button
        className="btn btn-danger btn-sm mr-1"
        onClick={() => Save()
        }
    >Save
    </button>
</Modal.Footer>
</Modal>
            <Paper className={classes.root}>
               

                <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={userdet}
                columns={columns}
                onPageChange={pageChange()}
              />


            </Paper>
            </div>
    );
}
