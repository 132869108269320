import React, { useState, useEffect,Fragment } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HistoryIcon from '@material-ui/icons/History';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import jwt_decode from 'jwt-decode';
import {toast} from 'react-toastify'
import { getdepositlist } from '../../actions/users';
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getKycHisotory } from '../../actions/users';
import { createFalse } from "typescript";
import ReactDatatable from '@ashvin27/react-datatable';
import socketClient from "socket.io-client";
import moment from 'moment';
var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setuserdet] = useState();
    const {id}=useParams();
    const[responsive,setresponsive]            = useState(true);
    const history = useHistory();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let lockVal;
    const kycHistory = async () => {
        console.log(id,"ffffffffffffff")
        var test = await getKycHisotory(id);
        if (test.userValue!=undefined) {
         console.log("test",test.userValue);
         setuserdet(test.userValue);
     }else{

     }
        
    }
    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    
    const historyt=useHistory();

    const back = async () => {
   //  window.location.href='/Mudrattrqqqq/kyclist';
     historyt.push('/Mudrattrqqqq/kyclist');
    }

    useEffect(() => {
    //logout(history)
     kycHistory();
    }, [])
    const columns = [
        {
            
    
            key: "_id",
            text: "User Id",
            className: "_id",
            align: "left",
            sortable: true
            
          },
       
       
        {
          key: "status",
          text: "Status",
          className: "Status",
          align: "left",
          sortable: true,
          cell: record => {
            console.log("recordrecodererereree125455",record)
            return(
            <Fragment>
                   {record.status && record.status==1 &&
                                        
                                        <span align={"left"}> Pending </span > 

                                       }
                                          {record.status && record.status==2 &&
                                        
                                        <span align={"left"}> Approved </span > 

                                       }
                                        {record.status && record.status==3 &&
                                        
                                        <span align={"left"}> Rejected</span > 

                                       }


            </Fragment>
            )
          }
    
        },
        {
            key: "date",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: true,
            cell:record=>
                // console.log(record.date,"date")
               <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
          },
       
      
      ];
     const configdata = {
                page_size: 10,
                length_menu: [ 10, 20, 50 ],
                filename: "Users",
                no_data_text: 'No user found!',
                button: {
                    print: true
                },
                language: {
                    length_menu: "Show _MENU_ result per page",
                    filter: "Filter in records...",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    pagination: {
                        first: "First",
                        previous: "Previous",
                        next: "Next",
                        last: "Last"
                    }
                },
                show_length_menu: true,
                show_filter: true,
                show_pagination: true,
                show_info: true,
                defaultSortAsc: true,
            };
    
    

    return (
        <div>
            <div className="page_header">
                  <button className="btn btn-success mr-3" onClick={() => back()}>Back</button> 
            </div>
        
            <div className="page_header">
                <h2>Kyc History List</h2>
                
            </div>
            <Paper className={classes.root}>
            <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={userdet}
                columns={columns}
                onPageChange={pageChange()}
              />

            </Paper>
            </div>
    );
}
