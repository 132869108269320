import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import config from "../../lib/config";
import isEmpty from "../../lib/isEmpty";

import { AddBankData, getBankData } from "../../actions/users";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  bankName: "",
  accountNo: "",
  holderName: "",
  bankCode: "",
  country: "",
  branchName: "",
  UpiId: "",
  qrImage: "",
  bankCity: "",
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const [FrontImage, setfront] = useState("");
  const [BackImage, setback] = useState("");

  useEffect(() => {
    getBankDetail();
  }, []);

  const getBankDetail = async () => {
    const { result, status } = await getBankData();
    if (status && !isEmpty(result)) {
      setFormValue(result);
      let img = `${config.API}/images/bankdetail/` + result.qrImage;
      console.log(img, "imgimg123");
      setback(img);
    }
  };

  // function
  const {
    bankName,
    accountNo,
    holderName,
    bankCode,
    country,
    branchName,
    UpiId,
    qrImage,
    bankCity,
  } = formValue;

  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    setValidateError("");
  };

  const handleFile = (event) => {
    event.preventDefault();
    console.log(event.target.files[0]);
    const { id, files } = event.target;
    setfront(URL.createObjectURL(event.target.files[0]));
    setback("");
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    setValidateError("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formValue, "formValueformValueformValue123");
    let reqData = {
      bankName,
      accountNo,
      holderName,
      bankCode,
      country,
      branchName,
      UpiId,
      qrImage,
      bankCity,
    };
    console.log(reqData, "reqDatareqDatareqData12345");
    let formdata = new FormData();
    formdata.append("bankName", bankName);
    formdata.append("accountNo", accountNo);
    formdata.append("holderName", holderName);
    formdata.append("bankCode", bankCode);
    formdata.append("country", country);
    formdata.append("branchName", branchName);
    formdata.append("UpiId", UpiId);
    formdata.append("qrImage", qrImage);
    formdata.append("bankCity", bankCity);

    let { error, result, status, message } = await AddBankData(formdata);
    console.log(result, "statusstatus");
    if (status) {
      setFormValue(initialFormValue);
      toast.success(message);
      setfront("");
      getBankDetail();
    } else {
      setValidateError(error);
    }
  };

  console.log(validateError, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Admin Bank Management
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="bankName" className="auth_label">
                      Bank Name
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="bankName"
                      value={bankName}
                      onChange={handleChange}
                    />
                    {validateError.bankName && (
                      <p className="text-danger">{validateError.bankName}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="accountNo" className="auth_label">
                      Account Number
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="accountNo"
                      value={accountNo}
                      onChange={handleChange}
                    />
                    {validateError.accountNo && (
                      <p className="text-danger">{validateError.accountNo}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="holderName" className="auth_label">
                      Bank Holder Name
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="holderName"
                      value={holderName}
                      onChange={handleChange}
                    />
                    {validateError.holderName && (
                      <p className="text-danger">{validateError.holderName}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="bankCode" className="auth_label">
                      Bank Code/IFSC Code
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="bankCode"
                      value={bankCode}
                      onChange={handleChange}
                    />
                    {validateError.bankCode && (
                      <p className="text-danger">{validateError.bankCode}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="country" className="auth_label">
                      Country
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="country"
                      value={country}
                      onChange={handleChange}
                    />
                    {validateError.country && (
                      <p className="text-danger">{validateError.country}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="branchName" className="auth_label">
                      Bank Branch Name
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="branchName"
                      value={branchName}
                      onChange={handleChange}
                    />
                    {validateError.branchName && (
                      <p className="text-danger">{validateError.branchName}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="UpiId" className="auth_label">
                      UPI ID
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="UpiId"
                      value={UpiId}
                      onChange={handleChange}
                    />
                    {validateError.UpiId && (
                      <p className="text-danger">{validateError.UpiId}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="bankCity" className="auth_label">
                      Bank City
                    </label>

                    <input
                      type="text"
                      className="auth_inp"
                      id="bankCity"
                      value={bankCity}
                      onChange={handleChange}
                    />
                    {validateError.bankCity && (
                      <p className="text-danger">{validateError.bankCity}</p>
                    )}
                  </GridItem>
                  <br /> <br /> <br />
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="UPI QR Image"
                      onChange={handleFile}
                      id="qrImage"
                      type="file"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.qrImage && (
                      <p className="text-danger">{validateError.qrImage}</p>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {FrontImage && (
                      <img
                        className="mt-3 w-100 noImage"
                        style={{ height: "150px", width: "100px" }}
                        src={FrontImage}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {BackImage && (
                      <img
                        className="mt-3 w-100 noImage"
                        style={{ height: "150px", width: "100px" }}
                        src={BackImage}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardBody>
                <GridContainer></GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
