import React, { useState, useEffect,Fragment } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import {toast} from 'react-toastify';
import {  getSupportDetails } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getWithdrawList } from '../../actions/users';
import { createFalse } from "typescript";
import config from '../../lib/config';
import SearchIcon from '@material-ui/icons/Search';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const history=useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [withdrawdet, setwithdrawdet] = useState();
    const [searchvalue,setsearchvalus]=useState("");
    const[responsive,setresponsive]            = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let lockVal;
  
   
    const GetWithdrawList=async ()=>{

        const {result}=await getWithdrawList();
         console.log("test..........",result.WithDrawData);
         setwithdrawdet(result.WithDrawData);
    }

    useEffect( () => {
        GetWithdrawList();
        
    }, [])

    const viewTicketDetails=(id)=>{
  window.location='/Mudrattrqqqq/WithDrawView/'+id;
  
    }
      const viewTicketDetails1=(id)=>{

  window.location='/Mudrattrqqqq/WithDrawDetails/'+id;
  
    }
 


    const handleChange=(e)=>{

        const value=e.target.value;
        setsearchvalus(value)  
        getSearchvalue(value);


    }
    const getSearchvalue= (searchvalue)=>{
    
        console.log("contact details",searchvalue)

        let fillerData=withdrawdet.filter(value=>{
            console.log("values",value);
              return (
                value._id && value._id.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.alternativeEmail && value.alternativeEmail.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.supportMessage && value.supportMessage.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.type && value.type.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    value.ticketStatus && value.ticketStatus.toLowerCase().includes(searchvalue.toLowerCase()) 


          );

        })
        if(searchvalue != ""){
            setwithdrawdet(fillerData);
        }else{
            GetWithdrawList();
        }

       
    }
    const columns = [
        {
            
    
            key: "_id",
            text: "User Id",
            className: "_id",
            align: "left",
            sortable: true
            
          },
        {
            
    
          key: "code",
          text: "Code",
          className: "code",
          align: "left",
          sortable: true
          
        },
       {
          key: "email",
          text: "Email",
          className: "Email",
          align: "left",
          sortable: true,
        
    
        },
       
        {
          key: "name",
          text: "Name",
          className: "name",
          align: "left",
          sortable: true,
    
        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            sortable: true,
      
          },
          {
            key: "receiveaddr",
            text: "Receive address",
            className: "receiveaddr",
            align: "left",
            sortable: true,
      
          },
        {
            key: "date",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: true,
            cell:record=>
                // console.log(record.date,"date")
               <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
          },
       
        {
                    key: "action",
                    text: "Action",
                    className: "action",
                    width: 200,
                    align: "left",
                    sortable: false,
                    cell: row => {
                        
                       
                        return (
                            <Fragment>
                            {row.status==1  &&
                                <div>
                                  <Link onClick={() => viewTicketDetails(row._id)}>
                                  <CheckBoxIcon  style={{ color: "black" }} />
                                  </Link>
                                  </div>
                            }
                                  {row.status==0 &&
                                                                                           
                                     <div>
                                  <Link onClick={() => viewTicketDetails1(row._id)}>
                                  <VisibilityIcon   style={{ color: "#b8256e" }} /></Link>
                                  </div>
                                }
                                  {row.status==2 &&
                                                                                           
                                     <div>
                                  <Link onClick={() => viewTicketDetails(row._id)}>
                                  <VisibilityIcon   style={{ color: "#b8256e" }} /></Link>
                                  </div>
                                }
                                {row.status==4 &&
                                                                                           
                                     <div>
                                  <p>user not approved email</p>
                                  </div>
                                }
                               
    
    
                            </Fragment>
                        );
                    }
                },
      ];
     const configdata = {
                page_size: 10,
                length_menu: [ 10, 20, 50 ],
                filename: "Users",
                no_data_text: 'No user found!',
                button: {
                    print: true
                },
                language: {
                    length_menu: "Show _MENU_ result per page",
                    filter: "Filter in records...",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    pagination: {
                        first: "First",
                        previous: "Previous",
                        next: "Next",
                        last: "Last"
                    }
                },
                show_length_menu: true,
                show_filter: true,
                show_pagination: true,
                show_info: true,
                defaultSortAsc: true,
            };
    return (
        <div>
            <div className="page_header">
                <h2>Withdraw Details List</h2>
                <div className="page_header">
                <div class="input-group mr-3">
                {/* <input type="text" class="form-control" value={searchvalue} onChange={handleChange} id="search" placeholder="search" /> */}
                {/* <div class="input-group-append">
                    <span class="input-group-text"><SearchIcon /></span>
                </div> */}
                </div>
                </div>
            </div>
          
            <Paper className={classes.root}>
            <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={withdrawdet}
                columns={columns}
                //onPageChange={pageChange()}
              />

            </Paper>
            </div>
    );
}
