import React, { useState, useEffect,Fragment } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HistoryIcon from '@material-ui/icons/History';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import socketClient from "socket.io-client";
import jwt_decode from 'jwt-decode';
import ClearIcon from '@material-ui/icons/Clear';
import {toast} from 'react-toastify';
import config from '../../lib/config';
import { getdepositlist } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getupii,updatefiatStatus,updatefiatStatusreject } from '../../actions/users';
import { createFalse } from "typescript";
import SearchIcon from '@material-ui/icons/Search';
import isEmpty from '../../lib/isEmpty';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import ReactDatatable from '@ashvin27/react-datatable';
import { Tune } from "@material-ui/icons";


var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '90%',
    },
    container: {
        maxHeight: 440,
    },
});
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
// const SERVER = "http://localhost:3001/";
const SERVER=config.API

var socket = socketClient(SERVER);
const token = localStorage.admin_token;

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setUser] = useState([]);
    const [searchvalue,setsearchvalus]=useState("");
    const [showModal, setShow] = useState(false);
    const [viewimage, setviewimage] = useState();
    const[responsive,setresponsive]            = useState(true);
    const[buttonstate,setbuttonstate]=useState(false)
    const[rowid,setrowid]=useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let lockVal;
    const getupi = async () => {
        var test = await getupii();
        console.log("testttttttttttttttt1256555",test.userValue);
        setUser(test.userValue);
        
    }
    
    const history=useHistory();
    const edit =(id)=>{
        //window.location='/kycview/'+id;
        history.push('/kycview/'+id);
    }
    
    const kycHistory=(id)=>{
        //window.location='/kycHistoryList/'+id;
        history.push('/kycHistoryList/'+id);

    }

    const handleClose = () => setShow(false);

    function showimage(record)
    {
        setviewimage(record.screenshotimg)
      setShow(true);
    
    }

    useEffect(() => {
    //logout(history)
     getupi();
    }, [])

    if(token && token !=""){

        const decoded = jwt_decode(token);
        console.log("decode", decoded._id)
        socket.emit('CREATEROOM', decoded._id)
        socket.on('kycstatus' + decoded._id, function (data,id) {
            console.log("emit_data",data,id);
            console.log(decoded._id,id);

            if(id== decoded._id)
            {
                console.log("ooooooo")
             setUser(data)
    
            }
          })
        }
        const fiatstatus = async (id,status,statusMessage) => {
          setrowid(id)
          console.log("dataaaaaaaaaa",id);
          setbuttonstate(true)
          var data = {id:id }
         let { error } = await updatefiatStatus(data);
              console.log(error);
        if (isEmpty(error)) {
            toast.success('Accepted', toasterOption);
            let ve=getupi();
            if(ve)
            setbuttonstate(false)
            // history.push('/kyclist')
        }
     
      }
   

      const fiatstatusreject = async (id,status,statusMessage) => {
        console.log("dataaaaaaaaaa",id);
        var data = {id:id }
       let { error } = await updatefiatStatusreject(data);
            console.log(error);
      if (isEmpty(error)) {
          toast.success('Rejected', toasterOption);
          
          getupi();
          // history.push('/kyclist')
      }
      else{
       // setvalidateError(error);
      }
    }

        const handleChange=(e)=>{

            const value=e.target.value;
            setsearchvalus(value)  
            getSearchvalue(value);
    
    
        }
        const getSearchvalue= (searchvalue)=>{
        
            console.log("contact details",searchvalue)
    
            let fillerData=userdet.filter(value=>{
                console.log("values",value);
                  return (
                    value.name && value.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.phonenumber && value.phonenumber.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.email && value.email.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        value.country && value.country.toLowerCase().includes(searchvalue.toLowerCase()) 
    
              );
    
            })


            userdet.map()
            if(searchvalue != ""){
                setUser(fillerData);
            }else{
                getupi();        
            }
    

           
        }
        
        const columns = [
          
            {
                
        
                key: "userid",
                text: "User Id",
                className: "_id",
                align: "left",
                sortable: true
                
              },
            {
                
        
              key: "amount",
              text: "Token",
              className: "Name",
              align: "left",
              sortable: true
              
            },
            {
                
        
              key: "depositamount",
              text: "Deposit Amount",
              className: "Name",
              align: "left",
              sortable: true
              
            },
           {
              key: "detposittype",
              text: "Deposit Type",
              className: "Email",
              align: "left",
              sortable: true,
              cell: record => {
                           
                if((record.detposittype=="INR")){
                    return (
                        <Fragment>
                          UPI
                          </Fragment>
                    )}
                    else{
                      return (
                        <Fragment>
                          Standard Detposit<br></br>({record.detposittype})
                          </Fragment>
                    )}
                    
                    }
        
            },
           
            {
              key: "email",
              text: "Email",
              className: "Mobile",
              align: "left",
              sortable: true,
              cell: record => {
          
                return (
                    <Fragment>
                      {record && record.userlist && record.userlist.length>0 && record.userlist[0].email}
        
                    </Fragment>
                )
              }
        
            },
            {
                key: "date",
                text: "Date",
                className: "Date",
                align: "left",
                sortable: true,
                cell:record=>
                    // console.log(record.date,"date")
                   <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
              },

              {
                key: "screenshotimg",
                text: "Screenshot Image",
                className: "Date",
                align: "left",
                sortable: true,
                cell:record=>
                    // console.log(record.date,"date")
                   <div><img src={config.API+"/images/sdDeposit/"+record.screenshotimg} width="90%" onClick={()=>showimage(record)}></img></div>
              },
           
            {
                        key: "action",
                        text: "Action",
                        className: "action",
                        width: 200,
                        align: "left",
                        sortable: false,
                        cell: record => {
                           
                        if((record.state!=1)&& (record.state!=2)){
                            return (
                              <div style={{ display: (buttonstate ? "none" : "block") }}>
                                <Fragment >
                                  
                                    <button  className="btn btn-success btn-sm mr-1" onClick={() => fiatstatus(record._id,2,"approved")} >
                                    Approved
        
                                   </button>
                                   <br></br> <br></br>
                                   <button  className="btn btn-danger btn-sm mr-1"  onClick={() => fiatstatusreject(record._id,2,"approved")}>
                                    Rejected
          
                                    </button>
        
                                </Fragment>
                                </div>
                            );
                        }else{

                          if(record.state==1){
                          return (
                            <Fragment>
                              Completed
                              </Fragment>
                          );
                        }else{
                          return (
                            <Fragment>
                              Rejected
                              </Fragment>
                          );
                        }
                      }
                      }
                    },
          ];
        
         const configdata = {
                    page_size: 10,
                    length_menu: [ 10, 20, 50 ],
                    filename: "Users",
                    no_data_text: 'No user found!',
                    button: {
                        print: true
                    },
                    language: {
                        length_menu: "Show _MENU_ result per page",
                        filter: "Filter in records...",
                        info: "Showing _START_ to _END_ of _TOTAL_ records",
                        pagination: {
                            first: "First",
                            previous: "Previous",
                            next: "Next",
                            last: "Last"
                        }
                    },
                    show_length_menu: true,
                    show_filter: true,
                    show_pagination: true,
                    show_info: true,
                    defaultSortAsc: true,
                };
        
        
    return (
        <div>
         <div className="page_header">
                <h2>User Fiat List</h2>
                <div className="page_header">
                <div class="input-group mr-3">
                {/* <input type="text" class="form-control" value={searchvalue} onChange={handleChange} id="search" placeholder="search" />
                <div class="input-group-append">
                    <span class="input-group-text"><SearchIcon /></span>
                </div> */}
                </div>
                </div>
            </div>
           
            <Paper className={classes.root}>
            <Paper className={classes.root}>
               

               <ReactDatatable
               responsive={responsive}
               config={configdata}
               records={userdet}
               columns={columns}
               //onPageChange={pageChange()}
             />


           </Paper>
               
            </Paper>


            <Modal show={showModal} onHide={handleClose} >


<Modal.Header closeButton>
    <Modal.Title>Screenshot Image</Modal.Title>
</Modal.Header>
<Modal.Body>
    <div class="form-group">
      
    <img src={config.API+"/images/sdDeposit/"+viewimage} width="100%"></img>               
     </div>
</Modal.Body>

<Modal.Footer>
<button
        className="btn btn-danger btn-sm mr-1"
        onClick={handleClose
        }
    >Close
    </button>
</Modal.Footer>
</Modal>
            </div>
    );
}
