import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import {  addToken ,getToken} from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
 
  'usdValue': "",
  "Bonus":"",
  "ETHvalue":"",
  "referral":"",
  "mindeposit":"",
  "minwithdraw":""
 

}

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  useEffect(()=>{
      gettoken();
  },[]);
 

const gettoken= async ()=>{

    const data=await getToken();
    console.log("111",data.DJvalue);
    const formdata={}
    formdata["usdValue"]   =data.DJvalue.Djacktoken;
    formdata["referral"]   =data.DJvalue.referralAmount;
    formdata["Bonus"]      =data.DJvalue.Bonus;
    formdata["mindeposit"] =data.DJvalue.mindeposit;
    formdata["minwithdraw"]=data.DJvalue.minwithdraw;

    setFormValue(formdata);

}
  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }

   setFormValue(formData)
  }

    const {
    
        usdValue,Bonus,ETHvalue,referral,mindeposit,minwithdraw
       
    } = formValue

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    console.log(formValue);
    let reqData = {
      
        usdValue,   usdValue,Bonus,ETHvalue,referral,mindeposit,minwithdraw
       
    }


    let { error,result } = await addToken(reqData);

    if (isEmpty(error)) {
        setValidateError('');
        getToken();
      toast.success(result, toasterOption);
    } else {
      setValidateError(error);
    }
  }
  
  return (
    <div>
    
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Change Token Value</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                  {/* <GridItem xs={12} sm={12} md={3}> */}
                  <GridItem xs={12} sm={12} md={12}> <h5 style={{ fontWeight: 'bold' }}>1 MUDRA Token</h5></GridItem>

             
                  <GridItem xs={12} sm={12} md={3}>
              <label htmlFor="inputPassword4" className="auth_label">INR VALUE</label>
             
              <input type="text" className="auth_inp" id="usdValue" value={usdValue}  onChange={onChange}/>

                   {/*  <CustomInput
                      labelText="USD VALUE"
                      onChange={onChange}
                      value={usdValue }
                      id="usdValue"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />*/}
                    {
                        validateError.usdValue && <span className="text-danger">{validateError.usdValue}</span>
                    }

                     {/* <br/><br/> <label htmlFor="inputPassword4" className="auth_label">Referral Amount &nbsp; (Djack Token)</label>
             
              <input type="text" className="auth_inp" id="referral" value={referral}  onChange={onChange}/>

                    <CustomInput
                      labelText="Referral Amount(Djacktoken)"
                      onChange={onChange}
                      value={referral}
                      id="referral"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                        validateError.referral && <span className="text-danger">{validateError.referral}</span>
                    }
                    
                 <br/><br/> <label htmlFor="inputPassword4" className="auth_label">Bonus %</label>
             
              <input type="text" className="auth_inp" id="Bonus" value={Bonus}  onChange={onChange}/>

                     <CustomInput
                      labelText="Bonus %"
                      onChange={onChange}
                      value={Bonus} 
                      id="Bonus"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                        validateError.Bonus && <span className="text-danger">{validateError.Bonus}</span>
                    } */}

                 <br/><br/> <label htmlFor="inputPassword4" className="auth_label">Minimum Deposit Token</label>
             
              <input type="text" className="auth_inp" id="mindeposit" value={mindeposit}  onChange={onChange}/>&nbsp;
     
                     {/* <CustomInput
                      labelText="Minimum Deposit"
                      onChange={onChange}
                      value={mindeposit}
                      id="mindeposit"
                      formControlProps={{
                        fullWidth: true
                      }}
                    /> */}
                    {
                        validateError.mindeposit && <span className="text-danger">{validateError.mindeposit}</span>
                    }
             {/* <br/><br/> <label htmlFor="inputPassword4" className="auth_label">Minimum Withdraw &nbsp; (Djack Token)</label>
             
              <input type="text" className="auth_inp" id="minwithdraw" value={minwithdraw}  onChange={onChange}/>
     
                    <CustomInput
                      labelText="Minimum Withdraw"
                      onChange={onChange}
                      value={minwithdraw}
                      id="minwithdraw"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                        validateError.minwithdraw && <span className="text-danger">{validateError.minwithdraw}</span>
                    } */}
                  </GridItem>
                </GridContainer>
              
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>  

          
      </GridContainer>
    </div>
  );
}
