import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ABI  from "../../ABI/ABI.json";
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';
import {  getuser,updateuser,getwithdraw,updateWithdraw ,Reject,getEthtransaction} from '../../actions/users';
import config from '../../lib/config'; 
import axios from "axios";
import loadinggif from "../../assets/images/preloader1.gif";

const  contractAddr = config.contractAddr;
const  smartcontract= config.smartcontract;
const  OwnerAddr    = config.owneraddr;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'name': "",
  'email': "",
  'phonenumber': "",
  'address1': "",
  'address2': "",
  'pincode': "",
  'city': "",
  'country': "",
  'Photofile' : "",
  "status":""

}

const useStyles = makeStyles(styles);

export default function WithDrawDetails(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [withdrawdet, setwithdrawdet] = useState();
  const [amount, setAmount] = useState();
  const [Withdrawstatus, setWithdrawstatus] = useState();
  const [curruserid, setUserId] = useState();
  const [tableid, settTableId] = useState();
  const [windowTron, setwindowTron]         = React.useState('');
  const [wallet_address, setWallet_address] = React.useState('---');
  const [upgradebtn, setupgradebtn]         = React.useState('');
  const [trxtransferid, setTrxtxid]         = useState('');
  const [transationID, setTransfer]         = useState('');
  const [trxuserid, setcurruserid]         = useState('');
  const [lasttransfer, setTransID]         = useState('');
  const [curruserAddr, setuserAddr]         = useState('');


  const { userId } = useParams();
  console.log(userId,"asdfdsfdsfdsf");

  const handleFile = (event) => {
    const { id, files } = event.target;
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));

    let formData = { ...formValue, ...{ [id]: files[0] } }
    setFormValue(formData)
    //setValidateError(formData)
  };

window.addEventListener('message', async function (e) {

    try{
      if (e.data.message && e.data.message.action == "tabReply") {
        console.log("tabReply event : ", e.data.message)
        if (typeof e.data.message.data != 'undefined'){
          if (typeof e.data.message.data.data != 'undefined'){
            if (typeof e.data.message.data.data.node != 'undefined'){
              if (typeof e.data.message.data.data.node.chain != 'undefined'){
                console.log("tronLink currently selects the main chain")
              } else {
                console.log("tronLink currently selects the side chain")
              }
            }
          }
        }
      }

      console.log('------ e : ',e);

      if (e.data.message && e.data.message.action == "setAccount") {
        console.log('setAccount e.data : ',e.data)
        var wallet_address = e.data.message.data.address;
        var loginName = e.data.message.data.name;
        var getName = localStorage.getItem('qas4567d');
        var encodedString = window.btoa( loginName );
        var decodedString = window.atob( getName );

        setWallet_address(wallet_address);

        var currAddr = wallet_address;
        // currAddr = 'TTxzx4KYE6uyUGgJmpeDv3mkRMRLTwaaVz';
        var total_deposits = 0;
        if(currAddr){
          var tronweb = tronWebconnect();
         

        } else {
          console.log('joinNowText : Join Now');
          
        }

        if(getName && getName!=null){
          console.log('getName : ',getName);
          if(decodedString!=loginName){
          console.log('decodedString : ',decodedString,' - loginName : ',loginName);
          console.log('wallet connection : ',loginName);
          console.log('window.tronWeb : ',window.tronWeb);


            localStorage.removeItem("qas4567d");
            localStorage.setItem('qas4567d', encodedString);
            setTimeout(function() {
              setwindowTron(window.tronWeb);
           
       
            },2000);

          }
        } else {
         localStorage.setItem('qas4567d', encodedString);
        }
      }
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message)
        if (e.data.message.data.node.chain == '_'){
          console.log("tronLink currently selects the main chain")
        } else {
          console.log("tronLink currently selects the side chain")
        }
      }
    }catch(e){
      console.log('wallet error',e)
    }
  });


 function tronWebconnect(){
    if(windowTron && windowTron.defaultAddress && windowTron.defaultAddress.base58){
      return windowTron;
    } else if(window.tronWeb && window.tronWeb.defaultAddress && window.tronWeb.defaultAddress.base58){
      return window.tronWeb;
    } else {
      return false;
    }
  }

  function fromHex(fromaddr){
    try{
      var tronWeb = window.tronWeb;
      var fromAddr = tronWeb.address.fromHex(fromaddr);
      console.log('fromHex^^^^^^^^^^^',fromAddr)
      return fromAddr;
    }catch(err){
    }
  }
  // console.log('smartConractAddress : ','TKNsutTtPGpQPuoABNA2WcBHFbr1W12ads')
 // console.log('smartConractAddress to hex : ',toHex('TBQaetwVgYZByEoAunuDTf9sr3TYPqf7uy'))
  function toHex(fromaddr){
    try{
      var tronWeb = window.tronWeb;
      var toAddr = tronWeb.address.toHex(fromaddr);
      console.log('toHex : ',toAddr)
      return toAddr;
    }catch(err){
    }
  }


  // function
  const onChange = (e) => {
    e.preventDefault();
   // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    // console.log(formValue);
    //setValidateError(formData)
  }

    const {
        name,
        email,
        phonenumber,
        address1,
        address2,
        pincode,
        city,
        country,
        Photofile,
    } = formValue

  const handleFormSubmit = async (e) => {
   console.log("muthu");
    e.preventDefault();

  }



   const getwithdrawList=async ()=>{

        const {result,userValue,data}=await getwithdraw(userId);
         console.log("test..........",userValue);
         if (userValue!=undefined) {
           
          setAmount(userValue.amount)
          setUserId(userValue.userid)
          settTableId(userValue.id)

          if (userValue.status!=undefined) {
            if (userValue.status==1) {
               setWithdrawstatus("pending")

            }else if (userValue.status==0) {
              setWithdrawstatus("Completed")

            }else if (userValue.status==2) {
              setWithdrawstatus("Rejected")

            }
           
          }
          
         }
         setwithdrawdet(result);
    }


  const getUserData = async () => {
    var test = await getuser(userId);
    console.log("userIduserIduserId",test)
    if (test.userValue!=undefined) {

      setuserAddr(test.userValue.useraddress)
    }
  
  }

  useEffect(() => {
    //logout(history)
    getwithdrawList();
    getUserData();

 getTransactionData();
  }, [])

 const getTransactionData = async () => {
    var test = await getEthtransaction();
    console.log('getTransactionData',test.userValue)
    let formdata = {};
    if (test &&test.userValue !=undefined) {
       if (test.userValue.length!=0) {

 
       var count= test.userValue.length-1;
console.log('getTransactionData',test.userValue[count]);

      setUser(test.userValue );

    formdata['id'] = test.userValue[count]._id;
    setcurruserid(test.userValue[count]._id);
   setTransID(test.userValue[count].useraddress)
 }else{

 }
  }else{

  }
  }

const back = async () => {
 window.location.href='/Mudrattrqqqq/withdrawlist';

}
const Approve = async () => {
  
var tronweb   = tronWebconnect();  
    

      if (tronweb) {
        var currAddr  = await tronweb.defaultAddress.base58;
        var contract  = await tronweb.contract(ABI).at(contractAddr)
     try{

        if (tronweb) {
        
  
          let currAddrObj = await tronweb.trx.getAccount(currAddr);
          console.log('currAddrObj:',currAddrObj)
          if(typeof currAddrObj.balance == 'undefined'){
         //  toastAlert('error',"Insufficient balance",'InsufficientBalance');
           toast.error('"Insufficient balance"', toasterOption); 

           return false;
          }

         if ( currAddr!=OwnerAddr) {

            toast.error('Invalid Your address', toasterOption); 

           return false;

         }

          var from = OwnerAddr;
          var toAddress=curruserAddr;
          var amount1   = amount*1000000000000000000;
          var value     = amount1.toString();
          var currAddr  = await tronweb.defaultAddress.base58;
          setWallet_address(currAddr);
          var curuser=currAddr;
          setValidateError("");

         var contract  = await tronweb.contract(ABI).at(contractAddr)
         var Sendtrx =await contract.transfer(
              toAddress,
              value
          ).send({

            feeLimit:1000000000

          });

          // let reqData = { amount, Withdrawstatus,curruserid,tableid};

        checktransaction(tableid,Sendtrx,curuser,amount1,curruserid)


        }else{


          toast.error('"Tron wallet  is not conneted"', toasterOption); 
         

        }

      }catch(err){
          setupgradebtn("");
          console.log('errerrerrerrerr',err)
          var errmsg = (err.message && err.message !="")?err.message:err;
          // toastAlert('error',errmsg,'tronbuy')
         toast.error(errmsg, toasterOption); 

      }

 }else{


         toast.error('Tron wallet  is not conneted', toasterOption); 


        }

}


var failedCount = 1;
async function checktransaction(tableid,txid,curuser,amount,curruserid){
console.log('txid',txid)


setTrxtxid(txid);

var tronweb = tronWebconnect();

var setInter = setInterval(async function () {
  failedCount++;
  var  TransactionInfo= await tronweb.trx.getTransactionInfo(txid);
  console.log('TransactionInfo:',TransactionInfo)

  if(TransactionInfo && TransactionInfo.receipt && TransactionInfo.receipt.result && TransactionInfo.receipt.result=="SUCCESS"){
     setTransfer(TransactionInfo.receipt.result);
     console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',failedCount)
     console.log(TransactionInfo.receipt,'1222222222223333333333331')
    clearInterval(setInter);
       toast.success('Tron using djack token buying successfully', toasterOption); 
       var postData        = {};
        postData.userid    = curruserid;
        postData.tableid   = tableid;
        postData.txid      = TransactionInfo.id;
        postData.fee       = TransactionInfo.fee;
        postData.status    = TransactionInfo.receipt.result;
        postData.curuser   = curuser;
        postData.value     = amount;
        postData.Djackvalue=amount;
        
         console.log(postData,'postDatapostData')
         
        axios
        .post(config.API + '/admin/updateWithdraw',postData)
        .then((res) => {
             console.log('res.data.message',res.data.message);
            
          if (res.data.message) {
           
            // toastAlert('success',"Join Successfully",'Join');
          } else {
            // toastAlert('error',"Join Failed",'Join');
          }
        }).catch();

     setTimeout(
        ()=>history.push('/admin/withdrawlist'),
        1000)

  }else if(TransactionInfo && TransactionInfo.receipt && TransactionInfo.receipt.result && TransactionInfo.receipt.result!="FAIL"){
     //setTransfer(TransactionInfo.receipt.result);
     console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$')
    clearInterval(setInter);
 toast.error('Your transaction is not completed', toasterOption);
  //  toastAlert('success',"Tron using your dajck token buying successfully",'JoinNowTransactionProcessing33');
     var postData = {};
          postData.userid    = curruserid;
          postData.tableid   = tableid;
        postData.txid      = TransactionInfo.id;
        postData.fee       = TransactionInfo.fee;
        postData.status1    = TransactionInfo.receipt.result;
        postData.curuser   = curuser;
        postData.value     = amount;
        postData.Djackvalue=amount;
        
        axios
        .post(config.API + '/admin/updateWithdraw',postData)
        .then((res) => {
             console.log('res.data.message',res.data.message);
             
          if (res.data.message) {
           
            // toastAlert('success',"Join Successfully",'Join');
          } else {
            // toastAlert('error',"Join Failed",'Join');
          }
        }).catch();

     setTimeout(
        ()=>history.push('/admin/withdrawlist'),
        1000)
  }else if(TransactionInfo && TransactionInfo.receipt && TransactionInfo.receipt.result && TransactionInfo.receipt.result!="SUCCESS"){
     //setTransfer(TransactionInfo.receipt.result);
     console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$')
    clearInterval(setInter);
  
 toast.success('Tron sdas 345', toasterOption);

     setTimeout(
        ()=>history.push('/admin/withdrawlist'),
        1000)
  }
}, 8000);

function resetInteval() {
    clearInterval(setInter);
}

}


const Rejectstatus = async()=>{

  let reqData ={ amount, Withdrawstatus,curruserid,tableid} ;


  let { error,result,userValue } = await Reject(reqData);
  console.log(result,'jjj')
  if (result) {

 toast.success("Withdraw status is Rejected", toasterOption); 

  }else{
 toast.error("Withdraw status is failed", toasterOption); 

  }

}

  return (
    
    <div>

    <button className="btn btn-success mr-3" onClick={() => back()}>Back</button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Withdraw Details</h4>
                <p className={classes.cardCategoryWhite}></p>
              </CardHeader>
              <CardBody>
                <GridContainer> 


                  <GridItem xs={12} sm={12} md={3}>
                 <br/><br/><br/>

                  <div className="djack">
                    <span ><b>Mudra Amount:&nbsp;{amount} &nbsp;Mudra</b></span>
                  </div><br/><br/>

                  <div className="withdraw" >
                    <span><b>Withdraw status:&nbsp;{Withdrawstatus}</b></span>
         
                         
                  </div>


      
                  </GridItem>
                                 
                </GridContainer>
              </CardBody>
            
            </form>

                <div className="text-center mt-3">
                { ((trxtransferid!='') || (trxtransferid!='' && transationID!='SUCCESS')) && 

                  <div className="gif_loader">

                    <img src={loadinggif} className="imgsrc" />

                  </div>
                        }
      
                {/*  
                  <button className="btn btn-danger" onClick={() => Rejectstatus()}>Reject</button>
               */}
                </div>
            
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
