import React, { useState, useEffect,Fragment } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import {toast} from 'react-toastify';
import {  getReferalHistory } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getSupportList,deletesupport } from '../../actions/users';
import { createFalse } from "typescript";
import config from '../../lib/config';
import SearchIcon from '@material-ui/icons/Search';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import key from "../../lib/config";
import axios from 'axios';

var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

  toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const history=useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [referalData, setreferalData] = useState([]);
    const [searchvalue,setsearchvalus]=useState("");
    const[responsive,setresponsive]            = useState(true);
const [dater,setdates]=useState('');


   
    const columns = [

      {

      key: "to",
      text: "From User",
      className: "Id",
      align: "left",
      sortable: true,
    },
   
    {

      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: false,
      
    },
   {
      key: "from",
      text: "To User",
      className: "To User",
      align: "left",
      sortable: true,
    

    },
   
    {
      key: "type",
      text: "Type ",
      className: "Type ",
      align: "left",
      sortable: true,

    },
  
    {

        key: "createdAt",
        text: "Created Date",
        className: "createdAt",
        align: "left",
        sortable: true,
        filter: 'between',
  
     
        cell: record => {

          var date = dateFormat(record.date, 'dd-mm-yyyy h:MM:ss TT');
  

      return date;

        }
  
      },
   
             
  ];


  const configdata = {
    page_size: 10,
    length_menu: [ 10, 20, 50 ],
    filename: "Users",
    no_data_text: 'No user found!',
    button: {
        print: true
    },
    language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last"
        }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
};

function pageChange(pageData) {
    console.log("OnPageChange", pageData);
}

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let lockVal;
  
   
    const getReferalHistoryData=async ()=>{

        const {result}=await getReferalHistory();
       if (result!=undefined) {
        setreferalData(result);
        }
    }

    useEffect( () => {
        getReferalHistoryData();
        
    }, [])

    const viewTicketDetails=(id)=>{
    window.location='/Mudrattrqqqq/ViewTicketList/'+id;
    }


 
    return (
        <div>
           
          
            <Paper className={classes.root}>
                  
            <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={referalData}
                columns={columns}
                onPageChange={pageChange()}
              />
            </Paper>
            </div>
    );
}
