import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import {toast} from 'react-toastify'
import { getdepositlist } from '../../actions/users';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getkyc,gettransation } from '../../actions/users';
import { createFalse } from "typescript";
import SearchIcon from '@material-ui/icons/Search';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
var dateFormat = require('dateformat');
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userdet, setUser] = useState();
    const [searchvalue,setsearchvalus]=useState("");
    const[responsive,setresponsive]  = useState(true);
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // const getKyc = async () => {
    //     var test = await getkyc();
    //     setUser(test.userValue);
        
    // }

   const columns = [
   
   {
      key: "email",
      text: "User",
      className: "user",
      align: "left",
      sortable: false,
      
       
      },
   
    {
      key: "txid",
      text: "TransactionId",
      className: "TransactionId",
      align: "left",
      sortable: false,

    },

        {
      key: "type",
      text: "Type",
      className: "Type",
      align: "left",
      sortable: false,
},
        {
      key: "amount",
      text: "Amount",
      className: "Amount",
      align: "left",
      sortable: false,

    },

         {
      key: "Mudravalue",
      text: "Mudra value",
      className: "Mudravalue",
      align: "left",
      sortable: false,

    },
         {
      key: "status",
      text: "Status",
      className: "Status",
      align: "left",
      sortable: false,

    },

    {
        key: "date",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: false,
        cell:record=>
            // console.log(record.date,"date")
           <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
      },
   

  ];
 const configdata = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                print: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };


   function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    
   
    useEffect(() => {
    // getKyc();
    gettransation1();
    }, [])

    const gettransation1 = async () => {

        var transactionValue = await gettransation();
        console.log(transactionValue,'transactionValue')
        setUser(transactionValue.userValue);
        
         }

         const handleChange=(e)=>{

            const value=e.target.value;
            setsearchvalus(value)  
            getSearchvalue(value);
    
    
        }
        const getSearchvalue= (searchvalue)=>{
        
            console.log("contact details",searchvalue)
    
            let fillerData=userdet.filter(value=>{
                // console.log("values",value);
                  return (
                      value._id && value._id.toLowerCase().includes(searchvalue.toLowerCase()) ||
                      value.txid && value.txid.toLowerCase().includes(searchvalue.toLowerCase()) ||
                      value.amount && value.amount.toLowerCase().includes(searchvalue.toLowerCase()) ||
                      value.Djackvalue && value.Djackvalue.toLowerCase().includes(searchvalue.toLowerCase()) ||
                      value.status && value.status.toLowerCase().includes(searchvalue.toLowerCase()) ||
                      value.userid && value.userid.email && value.userid.email.toLowerCase().includes(searchvalue.toLowerCase())
              );
    
            })
            if(searchvalue != ""){
                setUser(fillerData);
            }else{
                gettransation1();
            }
    
           
        }

    return (
        <div>
        
        <div className="page_header">
                <h2>Transaction List</h2>
               
            </div>
            <Paper className={classes.root}>
            <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={userdet}
                columns={columns}
                onPageChange={pageChange()}
              />
            </Paper>
            </div>
    );
}
