import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import { updateSettings, getsettdata } from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
    "facebook" : "",
    "fees" : "",
    "twitter" : "",
    "telegram" : "",

}



const useStyles = makeStyles(styles);



export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState({});
  const [validateError, setValidateError] = useState({});



  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const {
    facebook,
    fees,
    twitter,
    telegram,
  } = formValue

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();

    let reqData = {     
        facebook,
        fees,
        twitter,
        telegram,
    }
  //  console.log(reqData);
    let {error ,result} = await updateSettings(reqData);
    if (isEmpty(error)) {
      toast.success('User settings Updated', toasterOption);
      setValidateError("");
      history.push('/settings')
    } else {
      setValidateError(error);
    }
  }

  const getUserData = async () => {
    var test = await getsettdata();
    let formdata = {};
    console.log(test.userValue);
    formdata['fees'] = test.userValue.fees;
    formdata['twitter'] = test.userValue.twitter;
    formdata['facebook'] = test.userValue.facebook;
    formdata['telegram'] = test.userValue.telegram;
    console.log("----formdata", formdata)
    setFormValue(formdata);
  }

  useEffect(() => {
    //logout(history)
    getUserData();
  }, [])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Edit Settings</h4>
                <p className={classes.cardCategoryWhite}>Update Settings</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                     labelText="Fees For Withdraw"
                     onChange={onChange}
                     id="fees"
                     value={fees || ''}
                     formControlProps={{
                       fullWidth: true
                     }}
                    />
                     {
                        validateError.fees && <span className={classes.textDanger}>{validateError.fees}</span>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Facebook link"
                      onChange={onChange}
                      id="facebook"
                      value={facebook || ''}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                        validateError.facebook && <span className={classes.textDanger}>{validateError.facebook}</span>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Twitter Link"
                      onChange={onChange}
                      value={twitter || ''}
                      id="twitter"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {
                    validateError.twitter && <span className={classes.textDanger}>{validateError.twitter}</span>
                    }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Telegram Link"
                      onChange={onChange}
                      value={telegram || ''}
                      id="telegram"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {
                    validateError.telegram && <span className={classes.textDanger}>{validateError.telegram}</span>
                    }
                    </GridItem>
                </GridContainer>
                
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update Profile</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
