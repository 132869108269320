import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';
import config from  '../../lib/config'
import { updateProfile, getuserdata } from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'name': "",
  'email': "",
  'mobilenumber': "",
  'photo': "",
  'company': "",
  'designation': "",
  'detail': "",

}



const useStyles = makeStyles(styles);



export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [photoimage, setPhoto] = useState();
  const [formValue, setFormValue] = useState({});
  const [validateError, setValidateError] = useState({});

  const handleFile = (event) => {
    event.preventDefault();
    //alert("sasasasa");
    console.log(event.target.files[0])
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    const { id, files } = event.target;
     let formData = { ...formValue, ...{ [id]: files[0] } }
     setFormValue(formData)
  };


  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const {
    email,
    mobilenumber,
    photo,
    company,
    designation,
    detail,
    name,
  } = formValue

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();

    let reqData = {
      email,
      mobilenumber,
      photo,
      company,
      designation,
      detail,
      name,
    }

    let {error ,result} = await updateProfile(reqData);
    if (isEmpty(error)) {
      toast.success('profile updated success', toasterOption);
      setValidateError("");
      history.push('/profile')
    } else {
      setValidateError(error);
    }
  }

  const getUserData = async () => {
    var test = await getuserdata();
    let formdata = {};
    formdata['email'] = test.userValue.email;
    formdata['mobilenumber'] = test.userValue.phoneNo;
    formdata['designation'] = test.userValue.designation;
    formdata['detail'] = test.userValue.about;
    formdata['name'] = test.userValue.name;
    setPhoto(test.userValue.profileImage);
    //formdata['photo'] = ;
    console.log("----formdata", test.userValue.profileImage)
    setFormValue(formdata);
  }

  useEffect(() => {
    //logout(history)
    getUserData();
  }, [])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
                <p className={classes.cardCategoryWhite}>Complete your profile</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Company (disabled)"
                      value="Mudra Token"
                      id="company-disabled"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Name"
                      onChange={onChange}
                      id="name"
                      value={name || ''}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

             {
                validateError.name && <span className={classes.textDanger}>{validateError.name}</span>
              }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
               
                    <CustomInput
                      labelText="Email address"
                      // onChange={onChange}
                      value={email || ''}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      disabled
                    />

             {
               validateError.email && <span className={classes.textDanger}>{validateError.email}</span>
             }
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Mobile"
                      onChange={onChange}
                      value={mobilenumber || ''}
                      id="mobilenumber"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

             {
               validateError.mobilenumber && <span className={classes.textDanger}>{validateError.mobilenumber}</span>
             }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Designation"
                      onChange={onChange}
                      id="designation"
                      value={designation || '' }
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

             {
               validateError.designation && <span className={classes.textDanger}>{validateError.designation}</span>
             }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Profile image"
                      onChange={handleFile}
                      id="photo"
                      type="file"                      
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                       {
               validateError.photo && <span className={classes.textDanger}>{validateError.photo}</span>
             }
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                    <CustomInput
                      labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                      id="detail"
                      onChange={onChange}
                      value={detail || ''}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5
                      }}
                    />
                  </GridItem>

                    {
                      validateError.detail && <span className={classes.textDanger}>{validateError.detail}</span>
                    }
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update Profile</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                {/* <img src={"http://3.139.192.96:2053/images/" + photo} alt="..." /> */}
                <img src={`${config.API}/images/` + photoimage} alt="..." /> 
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>{designation}</h6>
              <h4 className={classes.cardTitle}>{name}</h4>
              <p className={classes.description}>
              {detail}
              </p>
              {/* <Button color="primary" round>
                Follow
              </Button> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
